import ApiConnector from '@/models/ApiConnector';
import ServiceModel from '@models/ServiceModel';

const service = window.APP_CONFIG.services.find(service => service.id === 'map');

export const mapConnector = new ApiConnector({ baseURL: service?.configurations.api_endpoint });

export default class MapServiceModel extends ServiceModel {
	public static get connector() {
		return mapConnector;
	}

	public static serviceName = 'map';
	public static partitionName = '$';
}
