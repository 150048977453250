import Blamable                from '@/models/traits/Blamable';
import MapServiceModel         from '@/models/MapServiceModel';
import ProjectModel            from '@models/map/ProjectModel';
import StonecodeFileFileModel  from '@models/file/StonecodeFileFileModel';
import TimestampAble           from '@widesk/models/traits/TimestampAble';
import WithProjectPagePath     from '@/models/traits/WithProjectPagePath';
import doc                     from '@widesk/decorators/doc';
import model                   from '@widesk/decorators/model';
import reverseResolvable       from '@widesk/decorators/reverseResolvable';
import { computed }            from 'mobx';
import { getIdFromUrn }        from '@widesk/tools/identifier';
import LayerHelper             from '@/helpers/LayerHelper';

@model.urnResource('map')
@doc.path('/maps/{?id}')
export default class MapModel extends Blamable(TimestampAble(WithProjectPagePath(MapServiceModel))) {
	private declare _tsFilters: {
		'createdAt': string;
		'mapPlaces': id;
		'mapVersions': id;
		'project': id;
		'updatedAt': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'label': string;
		'updatedAt': string;
	};

	@doc.string declare label: string;
	@doc.model(ProjectModel) declare project: ProjectModel;

	@computed
	public get geoDataJsonFile() {
		return reverseResolvable(this, StonecodeFileFileModel, {
			propertyName: 'geoDataJsonFile',
			modelIdProperty: 'urn',
			getModelIdFromResolvable: async file => {
				await file.typeSource.source.whenIsLoaded();
				return file.typeSource.source.entityUrn;
			},
			filters: ({ urns }) => ({
				'typeSource.type.reference': 'geodata_json' as const,
				'typeSource.source.entityUrn': urns,
				// TODO Filtrer sur le statut
			}),
			onFetchSuccess: m => m.fetchGeoDataJson(),
		});
	}

	public get geoDataJson(): GEO_JSON | undefined {
		return this.geoDataJsonFile.get('data');
	}

	public setGeoDataJson(geo: GEO_JSON) {
		this.geoDataJsonFile.set('data', geo);
	}

	public async fetchGeoDataJson() {
		const blob = await this.geoDataJsonFile.fetchBlob();
		const text = await blob?.text();

		const geo = JSON.parse(text || 'false') as GEO_JSON | false;

		if (geo) {
			await LayerHelper.loadAllFromGeo(geo);
			this.setGeoDataJson(geo);
		}
	}

	@computed
	public get placeIds() {
		return this.geoDataJson?.zones.features
			.filter(zone => zone.properties.entityUrn)
			.map(zone => getIdFromUrn(zone.properties.entityUrn!)) || [];
	}
}
