import PlaceStatusModel       from '@models/map/PlaceStatusModel';
import ProjectModel           from '@models/map/ProjectModel';
import PlaceTypeModel         from '@models/map/PlaceTypeModel';
import doc                    from '@widesk/decorators/doc';
import model                  from '@widesk/decorators/model';
import MapServiceModel        from '@/models/MapServiceModel';
import TimestampAble          from '@widesk/models/traits/TimestampAble';
import Blamable               from '@/models/traits/Blamable';
import WithProjectPagePath    from '@/models/traits/WithProjectPagePath';
import StonecodeFileFileModel from '@models/file/StonecodeFileFileModel';
import { RenderNameProps }    from '@widesk/mixins/ApiModelRenderName';
import ApiCollection          from '@widesk/models/ApiCollection';
import FileHelper             from '@/helpers/FileHelper';

@model.urnResource('place')
@doc.path('/places/{?id}')
export default class PlaceModel extends Blamable(TimestampAble(WithProjectPagePath(MapServiceModel))) {
	private declare _tsFilters: {
		'createdAt': string;
		'exists[mapPlaces]': '0' | '1';
		'exists[mapVersionPlaces]': '0' | '1';
		'mapPlaces': id;
		'mapVersionPlaces': id;
		'placeFields': id;
		'placeType': id;
		'project': id;
		'updatedAt': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'label': string;
		'updatedAt': string;
	};	

	@doc.string declare label: string;
	@doc.string declare location: string;
	@doc.model(PlaceStatusModel) declare placeStatus: PlaceStatusModel;
	@doc.model(PlaceTypeModel) declare placeType: PlaceTypeModel;
	@doc.model(ProjectModel) declare project: ProjectModel;
	@doc.string declare reference: Map_PlaceReference;

	public get imageSrc(): string | undefined { // undefined si le file n'a pas encoré été chargé
		return this.get('imageSrc');
	}

	public renderName(props?: RenderNameProps<this> & RenderNameImageProps) {
		return super.renderName(props);
	}

	public fetchImage() {
		return PlaceModel.fetchImageForPlaces([this]);
	}

	public setImageSrcFromFile(file?: StonecodeFileFileModel | null) {
		this.set('imageSrc', FileHelper.getAmazonUrlFromFileId(file?.id, 'place_image/'));
	}

	public static async fetchImageForPlaces(places: PlaceModel[]) {
		const fileCollection = new ApiCollection(StonecodeFileFileModel);

		fileCollection.setRequiredFilter('typeSource.source.entityUrn', places.map(place => place.urn));
		fileCollection.setRequiredFilter('typeSource.type.reference', 'place_image' as const);

		await fileCollection.list();
		await fileCollection.whenIsLoaded(file => file.typeSource.source);

		places.forEach(place => {
			const file = fileCollection
				.find(file => file.typeSource.source.entityUrn === place.urn);

			place.setImageSrcFromFile(file);
		});
	}
}