import AccountModel    from '@models/account/AccountModel';
import doc             from '@widesk/decorators/doc';
import model           from '@widesk/decorators/model';
import MapServiceModel from '@/models/MapServiceModel';
import TimestampAble   from '@widesk/models/traits/TimestampAble';
import Blamable        from '@/models/traits/Blamable';

@model.urnResource('owner')
@doc.path('/owners/{?id}')
export default class OwnerModel extends Blamable(TimestampAble(MapServiceModel)) {
	private declare _tsFilters: {
		'projects': id;
	};

	private declare _tsSorts: {
		'id': string;
	};

	@doc.modelUrn(AccountModel) declare account: AccountModel;

	public get fullName() {
		return this.account.name;
	}
}