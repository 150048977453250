import { Modal }              from '@widesk/components/modal/Modal';
import { Action }             from '@widesk/components/ButtonActions';
import { DeleteOutlined }     from '@ant-design/icons';
import confirmModelMessage    from '@widesk/tools/actionModel/confirmModelMessage';
import ucFirst                from '@widesk/tools/ucFirst';
import { message }            from '@widesk/hooks/useMessage';
import { WithLocalizedLabel } from '@widesk/mixins/ApiModelLocalizedLabel';
import { WithRenderName }     from '@widesk/mixins/ApiModelRenderName';
import ApiModel               from '@widesk/models/ApiModel';

type DeleteOptions = {
	before?: () => void;
	callback?: () => void;
	content?: string;
	success?: string;
	title?: string;
	hideModelName?: boolean;
	actionProps?: Partial<Action>;
};

type M = ApiModel & WithLocalizedLabel & WithRenderName;

const deleteModelConfirm = (m: M, options: DeleteOptions = {}) => {
	const label = m.modelLabel;
	const title = options.title
		|| confirmModelMessage(m, { hideModelName: options.hideModelName });

	return Modal.confirm({
		okType: 'danger',
		content: options.content,
		onOk: async () => {
			try {
				if (options.before) {
					await options.before();
				}

				await m.destroy();

				if (options.callback) {
					options.callback();
				}

				const success = options.success
					|| ucFirst(`L'élément ${options?.hideModelName ? '' : `${m.localize()} `}"${label}" a bien été supprimé.`);

				message.success(success);
			} catch (err) {
				message.exception(err);
			}
		},
		title,
	});
};

const actionDeleteModel = (
	model: M,
	options?: DeleteOptions,
) => {
	return {
		danger: true,
		icon: <DeleteOutlined />,
		label: `Supprimer` + (options?.hideModelName ? '' : ` - ${model.localize()}`),
		onClick: async () => deleteModelConfirm(model, options),

		...options?.actionProps,
	};
};

export default actionDeleteModel;
