import axios                 from 'axios';
import FileModel             from '@/models/services/file/StonecodeFileFileModel';
import { blobToBase64Async } from '@widesk/tools/blobTools';
import authStore             from '@widesk/stores/authStore';
import { API_ENV }           from '@/appConfig';

type CacheType = 'b64' | 'responseUrl';

const cacheSrc: Record<CacheType, Record<string, string>> = { b64: {}, responseUrl: {} };
const cacheSrcByUrn: Record<CacheType, Record<Urn, string>> = { b64: {}, responseUrl: {} };
const cacheSrcPromises: Record<CacheType, Record<string, Promise<string>>> = { b64: {}, responseUrl: {} };

const fetchSrcForFile = async (file: FileModel, options?: {
	mimeType?: string;
	type?: CacheType // default b64
}): Promise<string> => {
	const type = typeof options?.type === 'undefined' ? 'b64' : options?.type;

	const path = file.downloadPath;

	if (!path) {
		return '';
	}

	if (cacheSrc[type][path]) {
		return cacheSrc[type][path] || '';
	}

	if (cacheSrcByUrn[type][file.urn]) {
		return cacheSrcByUrn[type][file.urn] || '';
	}

	if (!cacheSrcPromises[type][path]) {
		cacheSrcPromises[type][path] = new Promise(resolve => {
			(async () => {
				const response = await axios.get(path, {
					headers: {
						'X-Request-Context': 'admin',
						'X-Platform-Authorization': `Bearer ${authStore.token}`,
					},
					responseType: type === 'b64' ? 'blob' : undefined,
				});

				const src = await (async () => {
					switch (type) {
						case 'responseUrl':
							return response.request.responseURL;

						case 'b64':
							return await blobToBase64Async(response.data, options?.mimeType) as string;

						default:
							return '';
					}
				})();

				cacheSrc[type][src] = src;
				cacheSrcByUrn[type][file.urn] = src;

				resolve(src);
			})();
		});
	}

	return cacheSrcPromises[type][path] || '';
};

const amazonPath = API_ENV === 'dev' ?
	`https://wbcdevtests.s3.eu-west-1.amazonaws.com/dev-tokamap-file/` :
	`https://tokamap-prod.s3.eu-west-3.amazonaws.com/production-tokamap-file/`;

const getAmazonUrlFromFileId = (id?: id | null, path?: string) => {
	if (id) return `${amazonPath}map/${path}${id.padStart(12, '0')}`;
	return '';
};

const getCachedSrc = (options: {
	type?: CacheType // Default b64
	for: FileModel | Urn // Default FileModel
}) => {
	const type = typeof options?.type === 'undefined' ? 'b64' : options?.type;

	if (options.for instanceof FileModel) {
		const src = options.for.downloadPath;

		if (cacheSrc[type][src]) {
			return cacheSrc[type][src] as string;
		}
	} else {
		const urn = options.for;

		if (cacheSrcByUrn[type][urn]) {
			return cacheSrcByUrn[type][urn];
		}
	}

	return '';
};

export default {
	fetchSrcForFile,
	getCachedSrc,
	getAmazonUrlFromFileId,
};
