import React                  from 'react';
import PagedCollection        from '@widesk/models/PagedCollection';
import Select                 from '@widesk/components/form/Select';
import { SelectProps }        from '@widesk/components/form/Select';
import { ModelClass }         from '@mathquis/modelx/lib/types/collection';
import ApiModel               from '@widesk/models/ApiModel';
import _debounce              from 'lodash/debounce';
import ApiCollection          from '@widesk/models/ApiCollection';
import { getIdFromUrn }       from '@widesk/tools/identifier';
import OneLineSkeleton        from '@widesk/components/OneLineSkeleton';
import View                   from '@widesk/components/View';
import { EditOutlined }       from '@ant-design/icons';
import { PlusCircleOutlined } from '@ant-design/icons';
import useTheme               from '@widesk/hooks/useTheme';
import actionModel            from '@widesk/tools/actionModel';
import useDidMount            from '@widesk/hooks/useDidMount';
import { message }            from '@widesk/hooks/useMessage';
import Observer               from '@widesk/components/Observer';
import { WithLocalizedLabel } from '@widesk/mixins/ApiModelLocalizedLabel';

export type SelectCollectionProps<M extends ModelClass<ApiModel>> = Partial<SelectProps> & {
	disabledOption?: (m: InstanceType<M>) => boolean;
	fetchOn?: 'mount' | 'open'; // Détermine quand la première requête est jouée (par défaut "open")
	identifier?: 'urn' | 'id'; // Propriété du model à utiliser pour la value (par défaut "urn")
	modelClass: M;
	filters?: ModelFilters<InstanceType<M>>;
	renderOption: (model: InstanceType<M>) => React.ReactNode;
	step?: number;
	sortName?: keyof ModelSorts<InstanceType<M>>;
	sortWay?: SortWay; // (par défaut "asc")
	renderForm?: (props: {
		model: InstanceType<M>;
		onSuccess?: () => void;
	}) => React.ReactNode; // Affichage du formulaire d'ajout/update pour le model
	formAddEnabled?: boolean;
	formEditEnabled?: boolean;
	translations?: LanguageKey | LanguageKey[];
	maxTagCount?: number | 'responsive'; // Affichage d'un tag comptant les options dépassant le nombre | la width de l'élément (par défaut). Pour voir le select s'agrandir indéfiniment, passer {undefined}.
	onFetchCollectionStart?: (collection: PagedCollection<InstanceType<M>>) => Promise<void | boolean>;
	onFetchCollectionEnd?: (collection: PagedCollection<InstanceType<M>>) => Promise<void>;
	onModelChange?: (model?: InstanceType<M>) => void; // Permet d'intercepter le model quand une option est sélectionnée
	exactSearchFilterName?: ModelFilterName<InstanceType<M>> // pour faire des recherches exactes avec un filter autre que search
}

type Model = ApiModel & WithLocalizedLabel;

export default function SelectCollection<M extends ModelClass<Model>>(props: SelectCollectionProps<M>) {
	const { modelClass, value, renderForm } = props;

	const theme = useTheme();

	const fetchOn = props.fetchOn || 'open';
	const identifier = props.identifier || 'urn';
	const step = props.step || 30;
	const disabledOption = props.disabledOption || (() => false);
	const sortName = props.sortName || 'id';
	const sortWay = props.sortWay || 'asc';
	const filters = props.filters || {};
	const values = Array.isArray(value) ? value : [value].filter(v => typeof v !== 'undefined');
	const property = identifier === 'urn' ? '@urn' : 'id';
	const formAddEnabled = !props.disabled && (typeof props.formAddEnabled === 'undefined' ? true : props.formAddEnabled);
	const formEditEnabled = !props.disabled && (typeof props.formEditEnabled === 'undefined' ? true : props.formEditEnabled);

	// Contient les models liés à la value et qui ne sont pas dans le state "models"
	const [valueModels, setValueModels] = React.useState<M[]>(() =>
		values.map(v => new modelClass({ [property]: v }) as unknown as M),
	);

	const [models, setModels] = React.useState<M[]>([]);
	const [loading, setLoading] = React.useState(fetchOn === 'mount');
	const [currentPage, setCurrentPage] = React.useState(1);
	const [hasMorePages, setHasMorePages] = React.useState(true);
	const [state] = React.useState({ isFocused: false, isMountedValue: false });
	const selectRef = React.useRef<any>(null); // Ref to the Select component
	const [status, setStatus] = React.useState<'error' | 'warning'>();

	// Mode fetch on mount
	useDidMount(async () => {
		if (fetchOn === 'mount') {
			await fetchData(1);
		}
	});

	// Quand la value change on va chercher le model qui correspond sur l'api
	React.useEffect(() => {
		(async () => {
			// Cette action n'est pas effectuée au premier chargement de la value dans le mode "mount"
			if (fetchOn !== 'mount' || state.isMountedValue) {
				try {
					const getValueId = (v: any) => identifier === 'urn' ? getIdFromUrn(v) : v;

					// On ne récupère pas les models qui sont déjà dans "models" ou "valueModels"
					// Sauf s'ils ne sont pas chargés
					const valueIds = values.map(v => getValueId(v)).filter(id =>
						![
							...valueModels.filter(vm => (vm as InstanceType<M>).isLoaded),
							...models,
						].some((m: any) => m.id === parseInt(id)),
					);

					const collection = new ApiCollection(modelClass);
					await collection.listBy(valueIds, 'id', { translations: props.translations });

					if (valueIds.length !== collection.length) {
						// Les models sélectionnés n'ont pas été trouvés sur l'api
						console.error('SelectCollection', 'models not found', (modelClass as InstanceType<M>).localize(), valueIds);
						setStatus('error');
					}

					setValueModels(collection.map(m => m as unknown as M));
				} catch (err) {
					message.exception(err);
				}
			}
		})();

		state.isMountedValue = true;
	}, [value]);

	// Quand un chargement se termine
	React.useEffect(() => {
		setTimeout(() => selectRef.current?.scrollTo(step * (currentPage - 1)));
	}, [loading]);

	const fetchData = async (page: number, search = '') => {

		setLoading(true);

		const collection = new PagedCollection(modelClass);

		collection
			.setItemsPerPage(step)
			.setPage(page)
			.setFilters({ ...filters, 
				[search && props.exactSearchFilterName || 'search']: search,
			})
			.setSort(sortName as any, sortWay);

		if (props.onFetchCollectionStart) {
			await props.onFetchCollectionStart(collection as never);
		}

		await collection.list({ translations: props.translations });

		setHasMorePages(collection.hasNextPage);

		const newModels = (page === 1 ? [] : models).concat(collection.map(model => model as unknown as M));

		// On remplace les models de "newModels" par ceux qui sont déjà dans "valueModels"
		// Dans le but d'éviter de charger à nouveau les resolvables
		newModels.forEach((model: any, idx) => {
			const valueModel = valueModels.find((m: any) => m.get(property) === model.get(property));
			if (valueModel && (valueModel as InstanceType<M>).isLoaded) {
				newModels[idx] = valueModel;
			}
		});

		// On supprime le model de "valueModels" s'il est déjà dans "newModels"
		setValueModels(valueModels.filter((model: any) => !newModels.some((m: any) => m.get(property) === model.get(property))));
		setModels(newModels);

		if (props.onFetchCollectionEnd) {
			await props.onFetchCollectionEnd(collection as never);
		}

		setLoading(false);
	};

	const handleSearch = _debounce(async (search: string) => {
		setModels([]);
		setCurrentPage(1); // Reset the page to 1

		if (state.isFocused) { // Check if the select is in focus
			return fetchData(1, search); // Fetch new data for the first page
		}
	}, 600);

	const renderOption = (m: InstanceType<M>) => {
		return (
			<Observer render={() => {
				return (
					<View centerV inline heightF row gap={theme.marginXS}>
						{!!renderForm && formEditEnabled && (
							<View
								pointer
								color={theme.colorLink}
								hover={{ color: theme.colorLinkActive }}
								onMouseUp={e => e.stopPropagation()}
								onMouseDown={e => {
									e.stopPropagation();
									const { onClick } = actionModel.edit(m, renderForm({ model: m }));
									if (onClick) onClick();
								}}>
								<EditOutlined />
							</View>
						)}

						{props.renderOption(m)}
					</View>
				);
			}} />
		);
	};

	return (
		<View row centerV gap="xs">
			<Select
				ref={selectRef}
				loading={loading}
				loadingBottom={loading}
				maxTagCount={props.maxTagCount || 1}
				onPopupScroll={async (event) => {
					const target = event.target as any;
					if (!loading && hasMorePages && target.scrollTop + target.offsetHeight === target.scrollHeight) {
						setCurrentPage(prevPage => prevPage + 1);
						await fetchData(currentPage + 1);
					}
				}}
				onOpen={() => {
					if (
						(fetchOn === 'open' && !models.length)
						|| (fetchOn === 'mount' && !models.length)
					) {
						return fetchData(1);
					}
				}}
				options={[...valueModels, ...models].map((model) => ({
					disabled: disabledOption(model as InstanceType<M>),
					value: (model as any).get(property).toString(),
					label: renderOption(model as InstanceType<M>) || <OneLineSkeleton />,
				}))}

				onFocus={() => state.isFocused = true}
				onBlur={() => state.isFocused = false}
				onSearch={handleSearch}
				status={status}

				{...props}

				style={{ flex: 1, ...props.style }}

				onChange={value => {
					if (props.onChange) props.onChange(value);
					if (props.onModelChange) props.onModelChange(models.find(m => (m as any).get(property).toString() === value) as InstanceType<M>);
				}}
			/>

			{!!renderForm && formAddEnabled && (
				<View
					pointer
					color={theme.colorLink}
					hover={{ color: theme.colorLinkActive }}
					onClick={() => {
						const newModel = new (modelClass as any)();

						const { onClick } = actionModel.add(
							modelClass as never,
							renderForm({
								model: newModel,
								onSuccess: () => {
									const v = newModel.get(property);

									if (props.onChange) {
										props.onChange(props.multiple ? [...(props.value as any || []), v] : v);
									}
								},
							}),
						);
						if (onClick) onClick();
					}}
				>
					<PlusCircleOutlined />
				</View>
			)}
		</View>
	);
}
