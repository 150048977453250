import AccountStatusModel  from '@models/account/AccountStatusModel';
import doc                 from '@widesk/decorators/doc';
import model               from '@widesk/decorators/model';
import AccountServiceModel from '@/models/AccountServiceModel';
import TimestampAble       from '@widesk/models/traits/TimestampAble';
import Blamable            from '@/models/traits/Blamable';

@model.urnResource('account')
@doc.path('/accounts/{?id}')
export default class AccountModel extends Blamable(TimestampAble(AccountServiceModel)) {
	private declare _tsFilters: {
		'accountStatus': id;
		'accountStatus.reference': Account_AccountStatusReference;
		'accountStatusHistories': id;
		'accountSubscriptions': id;
		'userAccounts': id;
	};

	private declare _tsSorts: {
		'id': string;
		'name': string;
	};	

	@doc.model(AccountStatusModel) declare accountStatus: AccountStatusModel;
	@doc.string declare name: string;
}