import doc                      from '@widesk/decorators/doc';
import model                    from '@widesk/decorators/model';
import FileServiceModel         from '@/models/FileServiceModel';
import TimestampAble            from '@widesk/models/traits/TimestampAble';
import Blamable                 from '@/models/traits/Blamable';
import StonecodeFileSourceModel from '@models/file/StonecodeFileSourceModel';

@model.urnResource('stonecode_file_type_source')
@doc.path('/stonecode_file_type_sources/{?id}')
export default class StonecodeFileTypeSourceModel extends Blamable(TimestampAble(FileServiceModel)) {
	private declare _tsFilters: {
		'locked': '0' | '1';
		'source': id;
		'source.entityUrn': Urn;
		'type': id;
		'type.deprecated': '0' | '1';
		'type.reference': File_StonecodeFileTypeReference;
		'type.service': id;
		'type.service.serviceUrn': Urn;
		'type.system': '0' | '1';
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'updatedAt': string;
	};

	@doc.boolean declare locked: boolean;
	@doc.model(StonecodeFileSourceModel) declare source: StonecodeFileSourceModel;
}