import * as L           from 'leaflet';
import _get             from 'lodash/get';
import ApiCollection    from '@widesk/models/ApiCollection';
import PlaceModel       from '@models/map/PlaceModel';
import { getIdFromUrn } from '@widesk/tools/identifier';
import FileHelper       from '@/helpers/FileHelper';

const LayerHelper = {
	placeLayerOnLayerCenter: (
		layer: Layer | L.ImageOverlay,
		otherLayer: Layer,
	) => {
		const bounds1 = layer.getBounds();

		// Calcul des centres
		const center1 = layer.getBounds().getCenter();
		const center2 = otherLayer.getBounds().getCenter();

		// Déplacement de layer pour aligner les centres
		const latDiff = center2.lat - center1.lat;
		const lngDiff = center2.lng - center1.lng;

		const layerNorthEast = bounds1.getNorthEast();
		const layerSouthWest = bounds1.getSouthWest();

		const newBounds1 = L.latLngBounds([
			[layerNorthEast.lat + latDiff, layerNorthEast.lng + lngDiff],
			[layerSouthWest.lat + latDiff, layerSouthWest.lng + lngDiff],
		]);

		if (layer instanceof L.Rectangle || layer instanceof L.ImageOverlay) {
			layer.setBounds(newBounds1);
		}
	},

	placeImageOverlayInsideLayer: (
		imageLayer: L.ImageOverlay,
		layer: Layer,
		map: L.Map,
		options: {
			ratio?: number, // Permet de forcer le ratio (hauteur largeur) de l'image
			scale?: number // Entre 0 et 1, à 1 l'image prend l'espace maximum dans le Layer (Par défaut à 0.5)
		} = {},
	) => {
		const scale = typeof options.scale === 'undefined' ? 0.5 : options.scale;

		const ratio = options.ratio || (() => {
			const imgNE = map.latLngToContainerPoint(imageLayer!.getBounds().getNorthEast());
			const imgSW = map.latLngToContainerPoint(imageLayer!.getBounds().getSouthWest());

			const width = Math.abs(imgSW.x - imgNE.x);
			const height = Math.abs(imgSW.y - imgNE.y);

			return width / height;
		})();

		const pointNE = map.latLngToContainerPoint(layer!.getBounds().getNorthEast());
		const pointSW = map.latLngToContainerPoint(layer!.getBounds().getSouthWest());

		const width = Math.abs(pointSW.x - pointNE.x) * scale;
		const height = Math.abs(pointSW.y - pointNE.y) * scale;

		const layerRatio = width / height;

		if (ratio > layerRatio) {
			let newWidth = height * ratio;
			if (newWidth >= width) {
				newWidth = width;
				const newHeight = newWidth / ratio;
				pointNE.y = pointSW.y - newHeight;
			}

			pointNE.x = pointSW.x + newWidth;
		} else {
			let newHeight = width / ratio;
			if (newHeight >= height) {
				newHeight = height;
				const newWidth = newHeight * ratio;
				pointNE.x = pointSW.x + newWidth;
			}

			pointNE.y = pointSW.y - newHeight;
		}

		const newBounds = L.latLngBounds(map.containerPointToLatLng(pointSW), map.containerPointToLatLng(pointNE));

		imageLayer.setBounds(newBounds);

		LayerHelper.placeLayerOnLayerCenter(imageLayer, layer);
	},

	createPane: (name: PaneName, map: L.Map) => map.createPane(name),

	editPane: (layer: Layer | L.ImageOverlay, pane: PaneName, map: L.Map) => {
		layer.remove();
		layer.options.pane = pane;
		layer.addTo(map);
	},

	createCenterBounds: (map: L.Map, size = 100, aspectRatio = 1) => {
		const height = size / aspectRatio;

		const originLatLng = map.getCenter();
		const originPoint = map.latLngToContainerPoint(originLatLng);
		const nextCornerPoint = originPoint.add(new L.Point(size, -height));
		const nextCornerLatLng = map.containerPointToLatLng(nextCornerPoint);

		return L.latLngBounds([originLatLng, nextCornerLatLng]);
	},

	boundsStringToObject: (bounds?: string | L.LatLngBounds) => {
		if (bounds) {
			if (bounds instanceof L.LatLngBounds) return bounds;
			else return new L.LatLngBounds(Object.values(bounds as never));
		}
		return new L.LatLngBounds(new L.LatLng(0, 0), new L.LatLng(0, 0));
	},

	boundsIsZero: (b: L.LatLngBounds) => b.getNorth() === 0 && b.getSouth() === 0 && b.getEast() === 0 && b.getWest() === 0,

	latLngsToCoordinates: (latLngs: LatLngs): Coordinates => {
		return [
			latLngs.map(latlng => [latlng.lng, latlng.lat]) || [],
		];
	},

	latLngToPointCoordinates: (latLng: L.LatLng): PointCoordinates => {
		return [latLng.lng, latLng.lat];
	},

	latLngBoundsToCoordinates: (latLngBounds: L.LatLngBounds): Coordinates => {
		const sw = latLngBounds.getSouthWest();
		const ne = latLngBounds.getNorthEast();

		return [[
			[sw.lng, sw.lat],
			[ne.lng, sw.lat],
			[ne.lng, ne.lat],
			[sw.lng, ne.lat],
			[sw.lng, sw.lat],
		]];
	},

	coordinatesToLatLngBounds: (coordinates: Coordinates): L.LatLngBounds => {
		const coords = coordinates[0];

		// Vérifier que les coordonnées sont bien formées
		if (!Array.isArray(coords) || coords.length === 0) {
			throw new Error('Les coordonnées du GeoJSON ne sont pas valides.');
		}

		// Initialiser les valeurs min et max
		let minLat = Infinity, minLng = Infinity, maxLat = -Infinity, maxLng = -Infinity;

		coords.forEach(([lng, lat]) => {
			if ((lat || 0) < minLat) minLat = lat as any;
			if ((lng || 0) < minLng) minLng = lng as any;
			if ((lat || 0) > maxLat) maxLat = lat as any;
			if ((lng || 0) > maxLng) maxLng = lng as any;
		});

		// Créer l'objet LatLngBounds
		return L.latLngBounds(
			L.latLng(minLat, minLng),
			L.latLng(maxLat, maxLng),
		);
	},

	coordinatesToLatLngs: (coordinates: Coordinates): LatLngs => {
		return coordinates[0]?.map(c => L.latLng(c[1]!, c[0]!)) || [];
	},

	coordinatesToLatLng: (coordinates: PointCoordinates): L.LatLng => {
		return L.latLng(coordinates[1]!, coordinates[0]!);
	},

	checkClickOnMap: (e: L.LeafletMouseEvent) => {
		const classList = _get(e, 'originalEvent.target.classList') as DOMTokenList | undefined;

		return classList?.contains('leaflet-container');
	},

	async loadAllFromGeo(geo: GEO_JSON): Promise<GEO_JSON> {
		const placeIds = geo.zones.features
			.map(feature => feature.properties.entityUrn)
			.filter(urn => urn)
			.map(urn => getIdFromUrn(urn!));

		const placeCollection = new ApiCollection(PlaceModel);
		await placeCollection.listBy(placeIds, 'id', { maxResults: 3000 });
		await PlaceModel.fetchImageForPlaces(placeCollection.models);

		await Promise.all(geo.zones.features.map(async feature => {
			if (feature.properties.entityUrn) {
				const place = placeCollection.find(place => place.urn === feature.properties.entityUrn);
				if (place) {
					feature.properties.entity = {
						id: place.id,
						label: place.label,
						image: place.imageSrc || '',
						location: place.location,
						reference: place.reference,
					};
				}
			} else if (feature.properties.resourceUrn && !feature.properties.resourceUrl) {
				feature.properties.resourceUrl = FileHelper.getAmazonUrlFromFileId(
					getIdFromUrn(feature.properties.resourceUrn as Urn),
					'map_image/',
				);
			}
		}));

		return geo;
	},

	getBounds(layer: Layer) {
		if (layer instanceof L.Circle) {
			// Récupérez le centre du cercle
			const center = layer.getLatLng();

			// Récupérez le rayon du cercle (en mètres)
			const radius = layer.getRadius();

			// Utilisez les utilitaires de Leaflet pour calculer les bounds
			const southWest = L.latLng(center.lat - (radius / 40075000 * 360), center.lng - (radius / 40075000 * 360 / Math.cos(center.lat * Math.PI / 180)));
			const northEast = L.latLng(center.lat + (radius / 40075000 * 360), center.lng + (radius / 40075000 * 360 / Math.cos(center.lat * Math.PI / 180)));

			return L.latLngBounds(southWest, northEast);
		}

		return layer.getBounds();
	},
};

export default LayerHelper;