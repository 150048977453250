import ApiConnector from '@/models/ApiConnector';
import ServiceModel from '@models/ServiceModel';

const service = window.APP_CONFIG.services.find(service => service.id === 'file');

export const fileConnector = new ApiConnector({ baseURL: service?.configurations.api_endpoint });

export default class FileServiceModel extends ServiceModel {
	public static get connector() {
		return fileConnector;
	}

	public static serviceName = 'file';
	public static partitionName = '$';
}
