import { FolderOutlined } from '@ant-design/icons';
import { TeamOutlined }   from '@ant-design/icons';
import ProjectModel       from './models/services/map/ProjectModel';
import UserModel          from './models/services/account/UserModel';

export default [
];

export const navigationSettings = [
	{ label: ProjectModel.localize('plural'), icon: <FolderOutlined />, link: ProjectModel.pagePath('splitView') },
	{ label: UserModel.localize('plural'), icon: <TeamOutlined />, link: UserModel.pagePath('splitView') },
];
