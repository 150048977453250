import AccountModel           from '@models/account/AccountModel';
import UserModel              from '@models/account/UserModel';
import UserAccountStatusModel from '@models/account/UserAccountStatusModel';
import UserAccountTypeModel   from '@models/account/UserAccountTypeModel';
import doc                    from '@widesk/decorators/doc';
import model                  from '@widesk/decorators/model';
import AccountServiceModel    from '@/models/AccountServiceModel';
import TimestampAble          from '@widesk/models/traits/TimestampAble';
import Blamable               from '@/models/traits/Blamable';

@model.urnResource('user_account')
@doc.path('/user_accounts/{?id}')
export default class UserAccountModel extends Blamable(TimestampAble(AccountServiceModel)) {
	private declare _tsFilters: {
		'account': id;
		'user': id;
		'userAccountStatus': id;
		'userAccountStatus.reference': Account_UserAccountStatusReference;
		'userAccountStatusHistories': id;
		'userAccountType': id;
	};

	private declare _tsSorts: {
		'id': string;
		'user.firstname': string;
		'user.lastname': string;
	};

	@doc.model(AccountModel) declare account: AccountModel;
	@doc.model(UserModel) declare user: UserModel;
	@doc.model(UserAccountStatusModel) declare userAccountStatus: UserAccountStatusModel;
	@doc.model(UserAccountTypeModel) declare userAccountType: UserAccountTypeModel;
}