export const blobToBase64 = (
	blob: Blob,
	onLoad: (base64: string | ArrayBuffer | null) => void,
	onError?: () => void,
	mimeType?: string,
) => {
	const reader = new FileReader();

	reader.readAsDataURL(blob);
	reader.onload = () => {
		if (mimeType) {
			const base64String = (reader.result as string).split(',')[1]; // Récupérer uniquement la partie base64 après la virgule
			onLoad(`data:${mimeType};base64,${base64String}`);
		} else {
			onLoad(reader.result);
		}
	};

	if (onError) {
		reader.onerror = onError;
	}
};

export const blobToBase64Async = (blob: Blob, mimeType?: string): Promise<string | ArrayBuffer | null> => {
	return new Promise((resolve, reject) => blobToBase64(blob, resolve, reject, mimeType));
};

export const showBlob = (blob: Blob) => window.open(URL.createObjectURL(blob));

export const downloadBlob = (blob: Blob, name = 'file.txt'): void => {
	// Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
	const blobUrl = URL.createObjectURL(blob);

	// Create a link element
	const link = document.createElement('a');

	// Set link's href to point to the Blob URL
	link.href = blobUrl;
	link.download = name;

	// Append link to the body
	document.body.appendChild(link);

	// Dispatch click event on the link
	// This is necessary as link.click() does not work on the latest firefox
	link.dispatchEvent(
		new MouseEvent('click', {
			bubbles: true,
			cancelable: true,
			view: window,
		}),
	);

	// Remove link from body
	document.body.removeChild(link);
};
