import doc              from '@widesk/decorators/doc';
import model            from '@widesk/decorators/model';
import FileServiceModel from '@/models/FileServiceModel';
import TimestampAble    from '@widesk/models/traits/TimestampAble';

@model.cacheDuration()
@model.urnResource('stonecode_file_mime_type')
@doc.path('/stonecode_file_mime_types/{?id}')
export default class StonecodeFileMimeTypeModel extends TimestampAble(FileServiceModel) {
	private declare _tsFilters: {
		'reference': FileStonecodeFileMimeType.Reference;
		'typeMimeTypes.type': id;
		'typeMimeTypes.type.reference': File_StonecodeFileTypeReference;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'reference': string;
		'updatedAt': string;
	};

	@doc.string declare reference: FileStonecodeFileMimeType.Reference;
}