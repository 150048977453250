import { ModelClass }         from '@mathquis/modelx/lib/types/collection';
import ApiModel               from '@widesk/models/ApiModel';

type ApiModelWithProject = ApiModel & { project: { id: string}};

export default function WithProjectPagePath<T extends ModelClass<ApiModel>>(Base: T) {
	const c = class WithProjectPagePath extends Base {

		public get pagePath(): string {
			return `/projects/${(this as unknown as ApiModelWithProject).project.id}${this.path}`;
		}
	};

	return c;
}
