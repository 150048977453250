import ApiCollection        from '@widesk/models/ApiCollection';
import ApiModel             from '@widesk/models/ApiModel';
import React                from 'react';
import useDidMount          from '@widesk/hooks/useDidMount';
import useWillUnmount       from '@widesk/hooks/useWillUnmount';
import { useAddCollection } from '@widesk/hooks/useAllCollections';

export default function useCollection<
	COLL extends ApiCollection<ApiModel>
>(createCollection: () => COLL, fetch = false) {
	const collection = React.useMemo(() => createCollection(), []);

	useDidMount(() => fetch && collection.list());
	useWillUnmount(() => collection.abort());

	useAddCollection(collection);

	return collection;
}
