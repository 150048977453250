import View                  from '@widesk/components/View';
import useTheme              from '@widesk/hooks/useTheme';
import LayoutHeaderLogout    from '@widesk/components/LayoutHeaderLogout';
import useThemeMode          from '@widesk/hooks/useThemeMode';
import appStore              from '@/stores/appStore';
import ButtonActions         from '@widesk/components/ButtonActions';
import useAccount            from '@/hooks/useAccount';
import AccountSelector       from './AccountWall/AccountSelector';

export default function LayoutHeader() {
	const { account } = useAccount();

	return (
		<View row spread>
			<View className="app__account-selector">
				{account && (
					<AccountSelector />
				)}
			</View>

			<View row>
				<UserHeaderSettings />

				<LayoutHeaderLogout />
			</View>
		</View>
	);
}

const UserHeaderSettings = () => {
	const { mode, setMode } = useThemeMode();
	const { colorPrimary } = useTheme();
	const isDark = mode === 'dark';

	return (
		<View bg={colorPrimary} color="white" center minWidth={150}>
			<ButtonActions
				actions={[
					{
						label: 'Afficher le thème ' + (isDark ? 'clair' : 'sombre'),
						onClick: () => setMode(isDark ? 'light' : 'dark'),
					},
				]}
				icon={appStore.user.renderName()}
				type="text"
				block
				style={{ color: 'white', height: '64px', borderRadius: 0 }}
			/>
		</View>
	);
};
