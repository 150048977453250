import Form          from '@widesk/components/form/Form';
import Input         from '@widesk/components/form/Input';
import { useAuth }   from '@widesk/hooks/useAuth';
import Box           from '@widesk/components/Box';
import View          from '@widesk/components/View';
import Button        from '@widesk/components/Button';
import useThemeMode  from '@widesk/hooks/useThemeMode';
import useTheme      from '@widesk/hooks/useTheme';
import React         from 'react';
import Spin          from '@widesk/components/Spin';
import InputPassword from '@widesk/components/form/InputPassword';
import useDidMount   from '@widesk/hooks/useDidMount';

type LoginPageProps = {
	header?: React.ReactNode;
	footer?: React.ReactNode;
}

export default function LoginPage(props: LoginPageProps) {
	const { login, isLogging } = useAuth();

	const { mode, setMode } = useThemeMode();
	const theme = useTheme();
	const isDark = mode === 'dark';

	useDidMount(() => document.title = 'Authentification');

	return (
		<View heightF center gap={theme.marginLG}>
			<Box style={{ width: 460, padding: theme.marginLG }}>
				<View gap={theme.marginXXL}>
					{props.header}

					<Spin size="large" spinning={isLogging}>
						<Form
							fields={{
								username: {
									span: 24,
									element: <Input id="username" placeholder="Votre identifiant" size="large" />,
									required: true,
								},
								password: {
									span: 24,
									element: <InputPassword id="password" placeholder="Votre mot de passe" size="large" />,
									required: true,
								},
							}}
							onSubmit={async ({ username, password }) => login(username as string, password as string)}
						>
							<Button size="large" block type="primary" htmlType="submit">
								Connectez-vous
							</Button>
						</Form>
					</Spin>

					{props.footer}
				</View>
			</Box>

			<Button type="link" onClick={() => setMode(isDark ? 'light' : 'dark')}>
				Afficher le thème {isDark ? 'clair' : 'sombre'}
			</Button>
		</View>
	);
}
