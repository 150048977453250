import doc             from '@widesk/decorators/doc';
import model           from '@widesk/decorators/model';
import MapServiceModel from '@/models/MapServiceModel';
import TimestampAble   from '@widesk/models/traits/TimestampAble';
import Blamable        from '@/models/traits/Blamable';

@model.cacheDuration()
@model.urnResource('place_status')
@doc.path('/place_statuses/{?id}')
export default class PlaceStatusModel extends Blamable(TimestampAble(MapServiceModel)) {
	private declare _tsFilters: {
		'createdAt': string;
		'placeStatusHistories': id;
		'places': id;
		'updatedAt': string | Date | Dayjs;
	};

	private declare _tsSorts: {
		'createdAt': string;
		'id': string;
		'label': string;
		'updatedAt': string;
	};	

	@doc.string declare label: string;
	@doc.string declare reference: Map_PlaceStatusReference;
	
	public get color() {
		switch (this.reference) {
			case 'enabled':
				return 'green';
			case 'disabled':
				return 'orange';
			default:
				return 'default';
		}
	}
}