
import AccountModel    from '@/models/services/account/AccountModel';
import appStore        from '@/stores/appStore';
import useDidMount     from '@widesk/hooks/useDidMount';
import { reaction }    from 'mobx';
import { useEffect }   from 'react';
import { useState }    from 'react';

const useAccount = () => {
	const [account, setAccount] = useState<AccountModel | null>(appStore.account);

	const selectAccount = (id: id | null) => {
		id ? appStore.fetchAccount(id) : appStore.resetAccount();
	};

	useDidMount(() => reaction(() => appStore.account, v => setAccount(v)));

	useEffect(() => {
		if (appStore.activeProject?.owner.account.id && appStore.activeProject.owner.account.id !== appStore.account?.id) {
			appStore.fetchAccount(appStore.activeProject.owner.account.id);
		}
	}, [appStore.activeProject?.owner.account.id]);

	return {
		account, 
		selectAccount,
		accountUrn: appStore.accountUrn,
	};
};

export default useAccount;