import ApiModel               from '@widesk/models/ApiModel';
import React                  from 'react';
import { Action }             from '@widesk/components/ButtonActions';
import { EditOutlined }       from '@ant-design/icons';
import { Modal }              from '@widesk/components/modal/Modal';
import { ModalProps }         from '@widesk/components/modal/Modal';
import { WithLocalizedLabel } from '@widesk/mixins/ApiModelLocalizedLabel';

type EditManyOptions<M extends typeof ApiModel> = {
	hideModelName?: boolean;
	modalProps?: Partial<ModalProps> | ((model: InstanceType<M>) => Partial<ModalProps>),
	actionProps?: Partial<Action>;
};

const actionEditManyModel = <MC extends typeof ApiModel & WithLocalizedLabel>(
	modelClass: MC,
	form: React.ReactNode,
	options?: EditManyOptions<MC>,
): Action => {
	const label = `Modifier les ${modelClass.localize('plural').toLowerCase()}`;

	return {
		label,
		icon: <EditOutlined />,
		onClick: () => Modal.open({ title: label, children: form, ...options?.modalProps }),
		...options?.actionProps,
	};
};

export default actionEditManyModel;
