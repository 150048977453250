import doc                 from '@widesk/decorators/doc';
import model               from '@widesk/decorators/model';
import AccountServiceModel from '@/models/AccountServiceModel';
import TimestampAble       from '@widesk/models/traits/TimestampAble';
import Blamable            from '@/models/traits/Blamable';

@model.cacheDuration()
@model.urnResource('user_account_status')
@doc.path('/user_account_statuses/{?id}')
export default class UserAccountStatusModel extends Blamable(TimestampAble(AccountServiceModel)) {
	private declare _tsFilters: {
		'reference': Account_UserAccountStatusReference;
		'userAccountStatusHistories': id;
		'userAccounts': id;
	};

	private declare _tsSorts: {
		'id': string;
		'label': string;
	};

	@doc.string declare label: string;
	@doc.string declare reference: Account_UserAccountStatusReference;
}